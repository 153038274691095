import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import RouterPropTypes from 'react-router-prop-types';
import { Container } from 'reactstrap';

import ContentPopups from '../../components/ad/ContentPopups';
import MainBanner from '../../components/ad/MainBanner';
import HelperNavigation from '../../components/common/HelperNavigation';
import PageTitle from '../../components/common/PageTitle';
import ScrollReset from '../../components/common/ScrollReset';
import WysiwygContent from '../../components/common/WysiwygContent';
import CanonicalLink from '../../components/head/CanonicalLink';
import RobotsMeta from '../../components/head/RobotsMeta';
import SEODescription from '../../components/head/SEODescription';
import SEOTitle from '../../components/head/SEOTitle';
import Page from '../../components/layout/Page';
import SidebarLayout from '../../components/layout/SidebarLayout';
import ContentForState from '../../components/loader/ContentForState';
import NavigationCategorySlider from '../../components/navigation/NavigationCategorySlider';
import MainProductList from '../../components/product-list/MainProductList';
import SingleRowProductTabs from '../../components/product-list/SingleRowProductTabs';
import ProductPdf from '../../components/product-pdf/ProductPdf';
import ProductTabs from '../../components/product/ProductTabs';
import DefaultSidebar from '../../components/sidebar/DefaultSidebar';
import PageSkeleton from '../../components/skeleton/PageSkeleton';
import SidebarSkeleton from '../../components/skeleton/SidebarSkeleton';
import ArinetWidget from '../../integrations/arinet/ArinetWidget';
import LoadBeeSectionContentWidget from '../../integrations/loadBee/LoadBeeSectionContentWidget';
import { modelOf } from '../../prop-types';
import RouteService from '../../services/RouteService';
import AccountStore from '../../store/AccountStore';
import AdStore from '../../store/AdStore';
import ConfigStore from '../../store/ConfigStore';
import SectionStore from '../../store/SectionStore';
import UIStore from '../../store/UIStore';
import CommonPage from '../../types/CommonPage';
import ProductPdfType from '../../types/ProductPdfType';

@observer
class SectionPage extends Component {
  constructor(props) {
    super(props);
    this.maybeLoadSection();
    this.maybeLoadAds();

    this.yotpoTimeout = null;
  }

  componentDidUpdate(prevProps) {
    const { configStore } = this.props;

    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.maybeLoadSection();
    }

    if (prevProps.match.path !== this.props.match.path) {
      if (configStore.integrations.yotpo.enabled && window && window.yotpo) {
        this.yotpoTimeout = setTimeout(() => {
          window.yotpo.refreshWidgets();
        }, 800);
      }
    }
  }

  componentWillUnmount() {
    this.yotpoTimeout && clearTimeout(this.yotpoTimeout);
  }

  maybeLoadSection = () => {
    const { sectionStore } = this.props;

    if (
      !sectionStore.sectionStates.get(sectionStore.activeSection.id) &&
      sectionStore.activeSection.content_html === null
    ) {
      sectionStore.loadSection(sectionStore.activeSection.id).catch((e) => {
        console.error(e);
      });
    }
  };

  maybeLoadAds = () => {
    const { adStore } = this.props;

    if (this.shouldHaveAds()) {
      adStore
        .loadAds(this.getAdParams(), '/section')
        .catch((e) => console.error(e));
    }
  };

  shouldHaveAds = () => {
    const { adStore } = this.props;
    return adStore.sectionPageAds.zones.length > 0;
  };

  shouldHaveMainAds = () => {
    const { adStore } = this.props;
    return adStore.sectionPageAds.zones.indexOf('MAIN_BANNER') !== -1;
  };

  shouldHaveSidebarAds = () => {
    const { adStore } = this.props;
    return adStore.sectionPageAds.zones.indexOf('SIDEBAR_BANNER') !== -1;
  };

  shouldHavePopup = () => {
    const { adStore } = this.props;
    return adStore.sectionPageAds.zones.indexOf('POPUP_CONTENT') !== -1;
  };

  getAdParams = () => {
    const { sectionStore } = this.props;

    return sectionStore.activeSection
      ? { section: sectionStore.activeSection.id }
      : null;
  };

  getSectionContent = () => {
    const { configStore, sectionStore, uiStore } = this.props;

    if (sectionStore.activeSection.hasContentIntegration) {
      return <ArinetWidget />;
    }

    return (
      <>
        {uiStore.isMobile &&
          sectionStore.activeSection.mainCategories.length > 0 &&
          configStore.navigation.common.showCategorySlider && (
            <NavigationCategorySlider
              categories={sectionStore.activeSection.mainCategories}
            />
          )}
        {configStore.sectionPage.singleRowTabs && (
          <SingleRowProductTabs
            searchParams={{
              sections: [sectionStore.activeSection.id],
            }}
          />
        )}
        {!configStore.siteConfig.isHomePage && this.getProductListing()}
      </>
    );
  };

  renderProductPdfButton = () => {
    const { sectionStore } = this.props;

    return (
      <ProductPdf
        activeId={sectionStore.activeSection.id.toString()}
        pdfType={ProductPdfType.SECTION}
        showEmail={true}
        requireEmail
      />
    );
  };

  getProductListing = () => {
    const { configStore, sectionStore } = this.props;
    const useMainProductList = configStore.sectionPage.useProductList;

    if (useMainProductList) {
      return (
        <MainProductList
          fixedParams={{
            cross_categories: [],
            sections: [sectionStore.activeSection.id],
          }}
          listId="section_list"
          name="Section List"
        />
      );
    } else {
      return (
        <ProductTabs
          searchParams={{
            sections: [sectionStore.activeSection.id],
          }}
        />
      );
    }
  };

  pdfCatalogEnabled = () => {
    const { accountStore, configStore } = this.props;
    const { showPdfButton } = configStore.sectionPage;

    if (showPdfButton === '0') {
      return false;
    }
    if (
      showPdfButton === '1' ||
      (showPdfButton === '2' && accountStore.loggedIn)
    ) {
      return true;
    }
    return showPdfButton === '3' && accountStore.isRetailer;
  };

  render() {
    const { adStore, configStore, sectionStore, uiStore, routeService, match } =
      this.props;

    if (sectionStore.activeSection.hasSectionIntegration) {
      return <LoadBeeSectionContentWidget />;
    }

    const mainBanner = this.shouldHaveMainAds() ? (
      <MainBanner
        searchParams={this.getAdParams()}
        shouldHaveMobile={adStore.sectionPageAds.hasMobile}
      />
    ) : null;
    const mainBannerWithinContent = configStore.banner.mainBanner.withinContent;

    return (
      <Page name={CommonPage.SECTION} className="SectionPage">
        <ScrollReset key={match.params.id} />
        {!mainBannerWithinContent && mainBanner}
        <Container className="SectionPage__container">
          <SidebarLayout
            sidebarPlaceHolder={!uiStore.isMobile ? <SidebarSkeleton /> : null}
            sidebar={
              <DefaultSidebar
                bannerSearchParams={
                  this.shouldHaveSidebarAds() ? this.getAdParams() : null
                }
              />
            }
            content={
              <ContentForState
                state={sectionStore.sectionStates.get(
                  sectionStore.activeSection.id
                )}
                error={sectionStore.lastError}
                forPlaceHolder={<PageSkeleton />}
                forLoaded={() => (
                  <>
                    {sectionStore.activeSection.isTestMode && (
                      <RobotsMeta noindex nofollow />
                    )}
                    <SEOTitle
                      title={
                        sectionStore.activeSection.SEO_title ||
                        sectionStore.activeSection.display_name
                      }
                      noSuffix={!!sectionStore.activeSection.SEO_title}
                    />
                    {sectionStore.activeSection.SEO_description && (
                      <SEODescription
                        content={sectionStore.activeSection.SEO_description}
                      />
                    )}
                    <CanonicalLink
                      path={routeService.getPath(
                        sectionStore.activeSection.path
                      )}
                    />
                    {mainBannerWithinContent && mainBanner}
                    <HelperNavigation
                      breadcrumbsPath={sectionStore.activeSection.breadcrumbs}
                    />
                    <PageTitle>
                      {sectionStore.activeSection.display_name}
                    </PageTitle>
                    {this.pdfCatalogEnabled() && this.renderProductPdfButton()}
                    {sectionStore.activeSection.content_html && (
                      <div className="SectionPage__section-content">
                        <WysiwygContent
                          html={sectionStore.activeSection.content_html}
                        />
                      </div>
                    )}
                    {this.getSectionContent()}
                    <div className="SectionPage__section-footer-content">
                      <WysiwygContent
                        html={
                          sectionStore.activeSection.footer_description_html
                        }
                      />
                    </div>
                    {this.shouldHavePopup() && (
                      <ContentPopups searchParams={this.getAdParams()} />
                    )}
                  </>
                )}
              />
            }
          />
        </Container>
      </Page>
    );
  }
}

SectionPage.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  adStore: modelOf(AdStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  sectionStore: modelOf(SectionStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  match: RouterPropTypes.match.isRequired,
  history: RouterPropTypes.history.isRequired,
  location: RouterPropTypes.location.isRequired,
};

export default inject(
  'accountStore',
  'adStore',
  'configStore',
  'sectionStore',
  'uiStore',
  'routeService'
)(withRouter(SectionPage));
