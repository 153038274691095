import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import CampaignCodeStore from '../../../store/CampaignCodeStore';
import { modelOf } from '../../../prop-types';
import globalTranslations from '../../../i18n/globalTranslations';
import CampaignCodeDiscountType from '../../../types/CampaignCodeDiscountType';
import ManufacturerStore from '../../../store/ManufacturerStore';
import ManufacturerLink from '../../manufacturer/ManufacturerLink';
import RouteService from '../../../services/RouteService';
import CategoryStore from '../../../store/CategoryStore';
import { formatPrice } from '../../../util/number';
import CurrencyStore from '../../../store/CurrencyStore';
import CampaignCodeProducts from '../CampaignCodeProducts';

@observer
export class CampaignCodeDiscount extends Component {
  getCategoryLimitDescription = () => {
    return (
      <div className="CampaignCodeDiscount__description">
        <FormattedMessage
          id="campaign.categoryLimitation"
          defaultMessage="Using this discount coupon, you will receive a discount for products belonging to the following product categories:"
        />
      </div>
    );
  };

  getManufacturerLimitDescription = () => {
    return (
      <div className="CampaignCodeDiscount__description">
        <FormattedMessage
          id="campaign.manufacturerLimitation"
          defaultMessage="Using this discount coupon, you will receive a discount for products from the following brands:"
        />
      </div>
    );
  };

  getTypeDescription = (discount) => {
    const { currencyStore } = this.props;
    let typeDescription = null;

    switch (discount.type) {
      case CampaignCodeDiscountType.AMOUNT:
        if (discount.amount) {
          typeDescription = (
            <FormattedMessage
              id="global.discountAmountSentence"
              defaultMessage="Discount: {discount}"
              values={{
                discount: formatPrice(
                  discount.amount,
                  currencyStore.activeCurrency
                )
              }}
            />
          );
        }
        break;
      case CampaignCodeDiscountType.PERCENTAGE:
        if (discount.percentage) {
          typeDescription = (
            <FormattedMessage
              id="global.discountPercentageSentence"
              defaultMessage="Discount: {discount} %"
              values={{ discount: discount.percentage }}
            />
          );
        }
        break;
      case CampaignCodeDiscountType.FREE_SHIPPING:
        typeDescription = (
          <FormattedMessage {...globalTranslations.freeDeliverySentence} />
        );
        break;
      default:
        break;
    }
    return typeDescription ? (
      <div className="CampaignCodeDiscount__type">{typeDescription}</div>
    ) : null;
  };

  getLimitedToProducts = (discount) => {
    if (!discount.limited_to_products) {
      return null;
    }
    return (
      <CampaignCodeProducts productIds={discount.limited_to_products.slice()} />
    );
  };

  getLimitedToCategories = (discount) => {
    const { categoryStore, routeService } = this.props;

    if (!discount.limited_to_categories) {
      return null;
    }

    const categoryItems = discount.limited_to_categories.map((limitation) => {
      const category = categoryStore.findCategoryById(limitation);
      if (!category) {
        return (
          <div key={'limitation-' + limitation}>
            <FormattedMessage
              id="campaign.categoryNotFound"
              defaultMessage="Given category {category} not found"
              values={{
                category: limitation
              }}
            />
          </div>
        );
      }

      return (
        <li key={category.id}>
          <Link
            className="CategoryLink"
            to={routeService.getPath(category.path)}
          >
            {category.name}
          </Link>
        </li>
      );
    });

    return (
      categoryItems && (
        <div className="CampaignCodeDiscount__category">
          <div className="CampaignCodeDiscount__category-desription">
            {this.getCategoryLimitDescription()}
          </div>
          <div className="CampaignCodeDiscount__category-list">
            <ul>{categoryItems}</ul>
          </div>
        </div>
      )
    );
  };

  getLimitedToManufacturers = (discount) => {
    const { manufacturerStore } = this.props;

    if (!discount.limited_to_manufacturers) {
      return null;
    }

    const manufacturerItems = discount.limited_to_manufacturers.map(
      (manufacturerId) => {
        const manufacturer = manufacturerStore.manufacturers.get(
          manufacturerId
        );

        return (
          manufacturer &&
          manufacturer.has_products && (
            <li key={manufacturer.id}>
              <ManufacturerLink manufacturer={manufacturer} />
            </li>
          )
        );
      }
    );

    return manufacturerItems && manufacturerItems.length > 0 ? (
      <div className="CampaignCodeDiscount__manufacturer">
        <div className="CampaignCodeDiscount__manufacturer-desription">
          {this.getManufacturerLimitDescription()}
        </div>
        <div className="CampaignCodeDiscount__manufacturer-list">
          <ul>{manufacturerItems}</ul>
        </div>
      </div>
    ) : (
      <FormattedMessage
        id="campaign.manufacturerMultiLimitation"
        defaultMessage="Allowed for limited manufacturers"
      />
    );
  };

  renderDiscounts = () => {
    const { campaignCodeStore } = this.props;

    if (campaignCodeStore.activeCodes.length > 0) {
      const activeCode = campaignCodeStore.activeCodes[0];
      const discounts = activeCode.discounts.map((discount) => {
        return (
          <div
            key={activeCode.code}
            className="CampaignCodeDiscount__campaign-code"
          >
            {this.getTypeDescription(discount)}
            {this.getLimitedToProducts(discount)}
            {this.getLimitedToCategories(discount)}
            {this.getLimitedToManufacturers(discount)}
          </div>
        );
      });
      return <div key={activeCode.code}>{discounts}</div>;
    }
  };

  render() {
    return <div className="CampaignCodeDiscount">{this.renderDiscounts()}</div>;
  }
}

CampaignCodeDiscount.propTypes = {
  campaignCodeStore: modelOf(CampaignCodeStore).isRequired,
  manufacturerStore: modelOf(ManufacturerStore).isRequired,
  categoryStore: modelOf(CategoryStore).isRequired,
  currencyStore: modelOf(CurrencyStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired
};

export default inject(
  'campaignCodeStore',
  'manufacturerStore',
  'categoryStore',
  'currencyStore',
  'routeService'
)(CampaignCodeDiscount);
