import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as MobxPropTypes, observer, inject } from 'mobx-react';
import { Link } from 'react-router-dom';

import { modelOf } from '../../../prop-types';
import Category from '../../../models/Category';
import RouteService from '../../../services/RouteService';

const NavigationCategorySlider = ({ routeService, categories }) => {
  const getImage = (name, image) => {
    return (
      <div className="NavigationCategorySlider__image">
        <img src={image} alt={name} title={name} />
      </div>
    );
  };

  const getCategoryItems = () => {
    return categories.map((category) => {
      return (
        <div key={category.id} className="NavigationCategorySlider__item">
          <Link
            className="CategoryLink"
            to={routeService.getPath(category.path)}
          >
            {category.image &&
              getImage(category.navigationName, category.image)}
            <div className="NavigationCategorySlider__name">
              {category.navigationName}
            </div>
          </Link>
        </div>
      );
    });
  };

  return (
    <div className="NavigationCategorySlider">
      <div className="NavigationCategorySlider__items">
        {getCategoryItems()}
      </div>
    </div>
  );
};

NavigationCategorySlider.propTypes = {
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  categories: PropTypes.oneOfType([
    MobxPropTypes.observableArrayOf(modelOf(Category)).isRequired,
    PropTypes.arrayOf(modelOf(Category)).isRequired,
  ]),
};

export default inject('routeService')(observer(NavigationCategorySlider));
