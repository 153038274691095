import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { modelOf } from '../../../prop-types';
import CurrencyStore from '../../../store/CurrencyStore';
import { formatPrice } from '../../../util/number';
import globalTranslations from '../../../i18n/globalTranslations';

@observer
export class Price extends Component {
  render() {
    const {
      price,
      muted,
      discount,
      currencyStore,
      className,
      showTaxExcludedInfo,
      showTaxIncludedInfo,
      prefix,
      suffix,
      fontSize,
      isMultiProductPicker
    } = this.props;

    let cssClasses = classNames('Price', className, {
      'Price--has-tax-excluded-info': showTaxExcludedInfo,
      'Price--discount': discount,
      'Price--muted': muted,
      'Price--price-wrapper': fontSize && !muted,
      'Price--multiProductPicker': isMultiProductPicker
    });
    // @TODO Remove dangerouslySetInnerHTML once the API no longer serves currencies as HTML entitites.
    return (
      <span className={cssClasses}>
        {prefix && <span className="Price__prefix">{prefix}</span>}
        <span
          className="Price--amount-wrapper"
          dangerouslySetInnerHTML={{
            __html: formatPrice(price, currencyStore.activeCurrency)
          }}
        />
        {suffix && <span className="Price__suffix">{suffix}</span>}
        {showTaxExcludedInfo &&
          !showTaxIncludedInfo && (
            <FormattedMessage {...globalTranslations.taxExcluded}>
              {(message) => (
                <span className="Price__tax-excluded-info">{message}</span>
              )}
            </FormattedMessage>
          )}
        {showTaxIncludedInfo &&
          !showTaxExcludedInfo && (
            <FormattedMessage {...globalTranslations.taxIncluded}>
              {(message) => (
                <span className="Price__tax-included-info">{message}</span>
              )}
            </FormattedMessage>
          )}
      </span>
    );
  }
}

Price.propTypes = {
  price: PropTypes.number.isRequired,
  currencyStore: modelOf(CurrencyStore).isRequired,
  prefix: PropTypes.node,
  suffix: PropTypes.node,
  className: PropTypes.string,
  discount: PropTypes.bool,
  muted: PropTypes.bool,
  showTaxExcludedInfo: PropTypes.bool,
  showTaxIncludedInfo: PropTypes.bool,
  fontSize: PropTypes.bool,
  isMultiProductPicker: PropTypes.bool
};

Price.defaultProps = {
  showTaxExcludedInfo: false,
  showTaxIncludedInfo: false
};

export default inject('currencyStore')(Price);
