import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { modelOf } from '../../../prop-types';
import ProductStore from '../../../store/ProductStore';
import ProductLink from '../../product/ProductLink';
import ContentForState from '../../loader/ContentForState';
import { paramsToQueryIdentifier } from '../../../util/query';
import RequestState from '../../../types/RequestState';
import ConfigStore from '../../../store/ConfigStore';

@observer
export class CampaignCodeProducts extends Component {
  componentDidMount() {
    this.loadProducts();
  }

  loadProducts = () => {
    const { productIds, productStore } = this.props;
    if (this.getCurrentSearchState() !== RequestState.LOADED) {
      productStore.loadProducts(
        {
          ids: productIds
        },
        true
      );
    }
  };

  getQueryIdentifier() {
    const { productIds } = this.props;
    return paramsToQueryIdentifier({
      ids: productIds
    });
  }

  getCurrentSearchState() {
    const { productStore } = this.props;
    return productStore.productQueryStates.get(this.getQueryIdentifier());
  }

  getProductLimitDescription = () => {
    return (
      <div className="CampaignCodeProducts__description">
        <FormattedMessage
          id="campaign.productLimitation"
          defaultMessage="Using this discount coupon, you will receive a discount for the following products:"
        />
      </div>
    );
  };

  getProductList = () => {
    const { productIds, productStore } = this.props;

    const productItems = productIds
      .map((productId) => {
        const product = productStore.products.get(productId);

        if (!product) {
          return null;
        }

        return (
          <li key={product.id}>
            <ProductLink requireCanonical product={product} />
          </li>
        );
      })
      .filter((item) => !!item);

    return (
      productItems && (
        <div className="CampaignCodeProducts">
          {this.getProductLimitDescription()}
          <div className="CampaignCodeProducts__list">
            <ul>{productItems}</ul>
          </div>
        </div>
      )
    );
  };

  render() {
    return (
      <ContentForState
        state={this.getCurrentSearchState()}
        forLoaded={() => {
          return this.getProductList();
        }}
      />
    );
  }
}

CampaignCodeProducts.propTypes = {
  productIds: PropTypes.array.isRequired,
  productStore: modelOf(ProductStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired
};

export default inject('productStore', 'configStore')(CampaignCodeProducts);
