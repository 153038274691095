import { types, getEnv, flow } from 'mobx-state-tree';
import { keyBy } from 'lodash';

import StatefulStore from '../models/StatefulStore';
import CampaignCode from '../models/CampaignCode';

const CampaignCodeStore = StatefulStore.named('CampaignCodeStore')
  .props({
    activeCodes: types.optional(types.array(CampaignCode), []),
  })
  .actions((self) => {
    return {
      setup: function (activeCodes) {
        self.activeCodes = keyBy(activeCodes, 'id');
        self.setLoading(false);
      },
      loadActiveCodes: flow(function* getActiveCodes() {
        self.setLoading(true);

        try {
          self.activeCodes = yield getEnv(self).apiWrapper.request(
            `campaign-codes`,
            {},
            { active_section: null }
          );
        } catch (error) {
          self.setError(error);
          throw error;
        }

        self.setLoading(false);
      }),
      setCode: flow(function* setCode(campaignCode) {
        yield getEnv(self)
          .apiWrapper.apiAxios()
          .post(`campaign-codes/set`, { campaignCode });

        yield self.loadActiveCodes();
      }),
      unsetCode: flow(function* unsetCode() {
        yield getEnv(self).apiWrapper.apiAxios().post(`campaign-codes/unset`);

        yield self.loadActiveCodes();
      }),
    };
  })
  .views((self) => ({
    get activeCoupon() {
      return !!self.activeCodes.length && self.activeCodes[0];
    },
    get campaignCodesArray() {
      return Array.from(self.activeCodes, ([key, value]) => value);
    },
  }));

export default CampaignCodeStore;
