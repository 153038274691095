import React, { Component } from 'react';
import { Container, Nav, Navbar, Row, Col, NavItem } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { debounce } from 'lodash';
import Sticky from 'react-sticky-el';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { autorun } from 'mobx';
import RouterPropTypes from 'react-router-prop-types';
import ReactResizeDetector from 'react-resize-detector';
import loadable from '@loadable/component';

import NavigationLogo from '../NavigationLogo';
import AccountLinks from '../../account/AccountLinks';
import NavigationMenuRow from '../../navigation/NavigationMenuRow';
import { modelOf } from '../../../prop-types';
import UIStore from '../../../store/UIStore';
import Icon from '../../common/Icon';
import NavigationNewsletterDropdown from '../NavigationNewsletterDropdown';
import NavigationRegionalSettingsMenu from '../NavigationRegionalSettingsMenu';
import NavigationTopLevelMegaMenu from '../NavigationTopLevelMegaMenu';
import NavigationLinkCarousel from '../NavigationLinkCarousel';
import NavigationCart from '../../cart/NavigationCart';
import SectionStore from '../../../store/SectionStore';
import ConfigStore from '../../../store/ConfigStore';
import CategoryStore from '../../../store/CategoryStore';
import globalTranslations from '../../../i18n/globalTranslations';
import NavigationSingleLevelMenuRow from '../../navigation/NavigationSingleLevelMenuRow';
import Paths from '../../../types/Paths';
import MenuStore from '../../../store/MenuStore';
import RouteService from '../../../services/RouteService';
import ClickOutside from '../../common/ClickOutside';
import SectionIntegrationType from '../../../types/SectionIntegrationType';
import AccountStore from '../../../store/AccountStore';
import NavigationWishListButton from '../NavigationWishListButton';
import TopNavigationSearchButton from '../TopNavigationSearchButton';
import CommonNavigationMiddle from '../CommonNavigationMiddle';
import QuickSearch from '../../search/QuickSearch';
import LinkType from '../../../types/LinkType';
import CustomNavigationLinkParentType from '../../../types/CustomNavigationLinkParentType';
import CustomNavigationLinkStore from '../../../store/CustomNavigationLinkStore';
import LicensePlateInput from '../../vehicle/LicensePlateInput';
import { transformInternalLink } from '../../../util/url';
import { addParametersToPath } from '../../../util/queryString';
import NavigationPopover from '../NavigationPopover';
import NavigationSectionSlider from '../../navigation/NavigationSectionSlider';
import NavigationCartPopover from '../../cart/NavigationCartPopover';
import ProductAddedToCardModalType from '../../../types/ProductAddedToCartModalStyle';
import TranslationStore from '../../../store/TranslationStore';
import MobileNavigationCart from '../../cart/MobileNavigationCart';
import NavigationProposal from '../../proposal/NavigationProposal';
import NavigationHybridCart from '../../cart/NavigationHybridCart';
import MobileNavigationProposal from '../../proposal/MobileNavigationProposal';

const MobileNavigation = loadable(() =>
  import(/* webpackChunkName: "navigation" */ '../MobileNavigation')
);

/**
 * TODO: Convert class component to function component for hooks
 */
@observer
export class CommonNavigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSticky: false,
      nonStickyHeaderHeight: null,
      showSearchOnMobile: false,
      showSearchOnDesktop: false,
      inputHasFocus: false,
    };
    this.stickyElement = null;
    this.headerTimeout = null;
  }

  componentDidMount() {
    const { uiStore } = this.props;
    const { showSearchOnDesktop, showSearchOnMobile } = this.props;
    if (!uiStore.isDesktop) {
      // Update header height on page load only if we're on mobile.
      this.updateHeaderHeight();
    }

    // Hide search field when the navbar is hidden on mobile
    autorun(
      () => {
        if (
          this.shouldHideSticky() &&
          showSearchOnMobile &&
          showSearchOnDesktop
        ) {
          this.setState(
            { showSearchOnMobile: false, showSearchOnDesktop: false },
            () => {
              this.updateHeaderHeight();
              this.blurElement();
            }
          );
        }
      },
      { delay: 500 }
    );

    // Attach resize event listener to window since ReactResizeDetector component reports resize event too early from landscape to portrait.
    !uiStore.isDesktop && !window.isSSR && this.attachResizeEventListener();
  }

  componentDidUpdate(_, prevState) {
    const { uiStore } = this.props;
    const { inputHasFocus, isSticky } = this.state;
    if (!uiStore.isDesktop && prevState.inputHasFocus !== inputHasFocus) {
      inputHasFocus && uiStore.toggleSearch(uiStore.isLandscape);
      !inputHasFocus && uiStore.toggleSearch(false);
      this.updateHeaderHeight();
    }

    if (prevState.isSticky !== isSticky) {
      this.updateHeaderHeight();
    }
  }

  componentWillUnmount() {
    const { uiStore } = this.props;
    if (this.headerTimeout) {
      clearTimeout(this.headerTimeout);
    }

    !uiStore.isDesktop &&
      !window.isSSR &&
      window.removeEventListener('resize', this.deviceOrientationHandler);
  }

  attachResizeEventListener = () => {
    window.addEventListener('resize', this.deviceOrientationHandler, false);
  };

  deviceOrientationHandler = () => {
    const { uiStore } = this.props;
    const { inputHasFocus } = this.state;
    inputHasFocus && uiStore.toggleSearch(uiStore.isLandscape);
    this.updateHeaderHeight();
  };

  onResize = () => {
    const { uiStore } = this.props;
    const { showSearchOnMobile } = this.state;

    if (!showSearchOnMobile || uiStore.isDesktop) {
      this.maybeResetSearchVisibilityState();
      this.updateHeaderHeight();
    }
  };

  maybeResetSearchVisibilityState = () => {
    if (this.props.uiStore.isDesktop) {
      this.setState({
        showSearchOnMobile: false,
      });
    } else {
      this.setState({
        showSearchOnDesktop: false,
      });
    }
  };

  updateHeaderHeight = () => {
    const { uiStore, configStore } = this.props;
    const { showSearchOnMobile, showSearchOnDesktop } = this.state;

    if (this.stickyElement) {
      const currentHeaderHeight = this.stickyElement.wrapperEl.clientHeight;
      uiStore.setHeaderHeight(currentHeaderHeight);
      const showSearch = !showSearchOnMobile && !showSearchOnDesktop;

      if (
        (!this.shouldShowMobileMenu() && showSearch) ||
        (configStore.topNavigation.moveSearchbarTopNavigation && !showSearch)
      ) {
        this.setState({
          nonStickyHeaderHeight: currentHeaderHeight,
        });
      }
    }
  };

  handleVehicleSearch = (licensePlate) => {
    const { routeService, history } = this.props;
    const licensePlateUrl = licensePlate
      ? addParametersToPath(Paths.VehiclePartSearch, {
          id: licensePlate,
        })
      : Paths.VehiclePartSearch;
    const url = routeService.getPath(licensePlateUrl);

    history.push(url);
  };

  onKeyPress = () => {
    this.blurElement();
  };

  onInputFocus = () => {
    this.setState({ inputHasFocus: true });
  };

  onInputBlur = () => {
    this.blurElement();
  };

  shouldShowFullHeader = () => {
    const { uiStore } = this.props;

    return (
      uiStore.isDesktop &&
      !this.searchIsFocused() &&
      !uiStore.scrollingDown &&
      uiStore.documentScrollTop <= uiStore.headerHeight &&
      uiStore.documentScrollTop !== 0 &&
      !this.shouldShowMobileMenu() &&
      !this.shouldShowMobileModal()
    );
  };

  shouldHideSticky = () => {
    const { uiStore, configStore } = this.props;
    const offset = uiStore.isDesktop ? 300 : 0;

    return (
      (!uiStore.isDesktop ||
        configStore.topNavigation.hideHeaderOnScrollingDown) &&
      !this.searchIsFocused() &&
      uiStore.scrollingDown &&
      uiStore.documentScrollTop > uiStore.headerHeight + offset &&
      !this.shouldShowMobileMenu() &&
      !this.shouldShowDesktopCanvas() &&
      !this.shouldShowMobileModal()
    );
  };

  shouldHideMixedContent = () => {
    const { configStore, sectionStore } = this.props;

    return (
      configStore.account.isMoneyTransfer ||
      (configStore.integrations.zoovu.enabled &&
        sectionStore.activeSection &&
        sectionStore.activeSection.integration_type ===
          SectionIntegrationType.ZOOVU)
    );
  };

  searchIsFocused = () => this.state.inputHasFocus;

  shouldShowMobileMenu = () => {
    const { uiStore } = this.props;
    return !uiStore.isDesktop && uiStore.mobileNavigation.isOpen;
  };

  shouldShowDesktopCanvas = () => {
    const { uiStore } = this.props;
    return uiStore.isDesktop && uiStore.navigationOffCanvas.isOpen;
  };

  shouldShowMobileModal = () => this.props.uiStore.popoverActive;

  onFixedToggle = (wasSticky) => this.setState({ isSticky: !wasSticky });

  getAdditionalLinks = () => {
    const { routeService, accountStore, configStore } = this.props;
    const additionalLinks = [];

    if (accountStore.isRetailer) {
      additionalLinks.push({
        id: '_customerProducts',
        label: (
          <FormattedMessage {...globalTranslations.customerProductsTitle} />
        ),
        path: routeService.getPath(Paths.CustomerProducts),
      });
    }

    if (configStore.navigation.sidebar.showOnSale) {
      additionalLinks.push({
        id: '_onSale',
        label: <FormattedMessage {...globalTranslations.onSaleTitle} />,
        path: routeService.getPath(Paths.OnSale),
      });
    }
    if (configStore.navigation.sidebar.showNewProducts) {
      additionalLinks.push({
        id: '_newProducts',
        label: <FormattedMessage {...globalTranslations.newProductsTitle} />,
        path: routeService.getPath(Paths.NewProducts),
      });
    }

    if (configStore.navigation.sidebar.showSoldProducts) {
      additionalLinks.push({
        id: '_soldProducts',
        label: <FormattedMessage {...globalTranslations.soldProductsTitle} />,
        path: routeService.getPath(Paths.SoldProducts),
      });
    }

    if (configStore.navigation.sidebar.showMostSold) {
      additionalLinks.push({
        id: '_mostSold',
        label: <FormattedMessage {...globalTranslations.mostPopularTitle} />,
        path: routeService.getPath(Paths.PopularProducts),
      });
    }

    if (configStore.navigation.sidebar.showRecommended) {
      additionalLinks.push({
        id: '_recommended',
        label: <FormattedMessage {...globalTranslations.weRecommendTitle} />,
        path: routeService.getPath(Paths.RecommendedProducts),
      });
    }

    return additionalLinks;
  };

  getBottomBarLinks = () => {
    const { sectionStore, categoryStore, uiStore, configStore } = this.props;

    let items;
    const closeMegaMenu = () =>
      uiStore.isDesktop ? uiStore.megaMenu.close() : null;

    if (configStore.activateSections) {
      items = sectionStore.activeSections.map((section) => {
        const toggleSection =
          section.mainCategoryIds && section.mainCategoryIds.length > 0
            ? () => uiStore.megaMenu.toggle(section)
            : () => uiStore.megaMenu.close();

        const onMouseOver = () =>
          uiStore.isDesktop ? this.debouncedHover(toggleSection) : null;
        const onMouseOut = () =>
          uiStore.isDesktop ? this.debouncedHover(closeMegaMenu) : null;

        const path = this.getCategoryOrSectionPath(section);
        return {
          id: section.id,
          label: (
            <span
              className="mouseClass"
              onClick={closeMegaMenu}
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
            >
              {section.navigationName}
            </span>
          ),
          path,
          className: classNames({
            open:
              uiStore.megaMenu.activeSection &&
              uiStore.megaMenu.activeSection.id === section.id,
          }),
          active: sectionStore.activeSection === section,
          children: [],
          type: section.link_type,
          customNavLinks: this.getCustomNavigationLinks(
            section,
            CustomNavigationLinkParentType.SECTION
          ),
        };
      });
    } else {
      items = categoryStore.categories.map((category) => {
        const toggleCategory =
          category.children && category.children.length > 0
            ? () => uiStore.megaMenu.toggle(category)
            : () => uiStore.megaMenu.close();

        const onMouseOver = () =>
          uiStore.isDesktop ? this.debouncedHover(toggleCategory) : null;
        const onMouseOut = () =>
          uiStore.isDesktop ? this.debouncedHover(closeMegaMenu) : null;

        const activeMainCategory =
          categoryStore.activeCategory &&
          categoryStore.activeCategory.mainCategory;

        const path = this.getCategoryOrSectionPath(category);
        return {
          id: category.id,
          label: (
            <span
              onClick={closeMegaMenu}
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
            >
              {category.navigationName}
            </span>
          ),
          path,
          className: classNames({
            open:
              uiStore.megaMenu.rootCategory &&
              uiStore.megaMenu.rootCategory.id === category.id,
          }),
          active: activeMainCategory === category,
          children: [],
          type: category.link_type,
          customNavLinks: this.getCustomNavigationLinks(
            category,
            CustomNavigationLinkParentType.CATEGORY
          ),
        };
      });
    }

    let additionalLinks = this.getAdditionalLinks();

    if (configStore.navigation.megaMenu.groupAdditionalLinks) {
      additionalLinks = this.groupAdditionalLinks(additionalLinks);
    }

    return items.concat(additionalLinks);
  };

  getCategoryOrSectionPath = (categoryOrSection) => {
    const { routeService } = this.props;

    if (categoryOrSection.link_type === LinkType.EXTERNAL_LINK) {
      return categoryOrSection.path;
    } else if (categoryOrSection.link_type === LinkType.INTERNAL_LINK) {
      return routeService.getPath(
        transformInternalLink(categoryOrSection.path)
      );
    } else {
      return routeService.getPath(categoryOrSection.path);
    }
  };

  getCustomNavigationLinks = (categoryOrSection, type) => {
    const { customNavigationLinkStore } = this.props;

    return type === CustomNavigationLinkParentType.CATEGORY
      ? customNavigationLinkStore.filterByCategoryId(categoryOrSection.id)
      : customNavigationLinkStore.filterBySectionId(categoryOrSection.id);
  };

  groupAdditionalLinks = (links) => {
    // No need to group, if only one item.
    if (links.length <= 1) {
      return links;
    }

    return [
      {
        id: '_customerProducts',
        label: (
          <FormattedMessage {...globalTranslations.additionalLinksTitle} />
        ),
        children: links,
        dropdown: true,
      },
    ];
  };

  /**
   * TODO: Convert to use "useNavigationEntityConverter" custom hook
   */
  convertMenuToNavigationEntity = (menu) => {
    let label;

    if (typeof menu.title === 'string') {
      label = (
        <div
          className="CommonNavigation__html-content"
          dangerouslySetInnerHTML={{
            __html: menu.title,
          }}
        />
      );
    } else if (menu.title != null) {
      label = <span className="CommonNavigation__title">{menu.title}</span>;
    } else {
      label = '';
    }

    return {
      id: menu.id,
      path: menu.url,
      type: menu.type,
      image: menu.image,
      label,
      children: menu.children.map(this.convertMenuToNavigationEntity),
      accessible_text: menu.accessible_text,
    };
  };

  getMenuBarLinks = () => {
    const { menuStore, routeService } = this.props;

    const items = menuStore.menus.map(this.convertMenuToNavigationEntity);

    items.unshift({
      id: '_top',
      path: routeService.getPath(Paths.FrontPage),
      type: LinkType.INTERNAL_LINK,
      image: null,
      label: (
        <>
          <Icon name="home" />
          <FormattedMessage {...globalTranslations.homeTitle} />
        </>
      ),
    });

    return items;
  };

  toggleSearch = () => {
    this.updateState('showSearchOnMobile');
    this.updateState('showSearchOnDesktop');
  };

  updateState = (stateVariable) => {
    this.setState(
      (prevState) => ({
        [stateVariable]: !prevState[stateVariable],
      }),
      () => this.focusElement()
    );
  };

  focusElement = () => {
    const { showSearchOnMobile, showSearchOnDesktop } = this.state;
    if ((showSearchOnMobile, showSearchOnDesktop)) {
      const element = this.getAutosuggestInput();

      if (element) {
        element.focus();
      }
    }
  };

  blurElement = () => {
    const element = this.getAutosuggestInput();

    if (element) {
      element.blur();
    }

    this.setState({
      inputHasFocus: false,
    });
  };

  // This debounced function allows us to have a single timeout for all hover
  // (onMouseOut and onMouseOver) callbacks so that only the last one is called.
  //
  // This allows us to
  // 1) prevent showing MegaMenu when the user hovers over the menubar quickly
  // 2) prevent hiding MegaMenu when the user moves the mouse from the menubar
  //    to the actual MegaMenu. This is achieved by having a separate onMouseOver
  //    in the MegaMenu that overrides the last onMouseOut callback.
  //
  debouncedHover = debounce((callback) => {
    if (callback) {
      callback();
    }
  }, 200);

  getNavigationCart = () => {
    const { accountStore, configStore, uiStore } = this.props;

    const doNotRenderCart = accountStore.isViewOnly;

    if (doNotRenderCart) {
      return null;
    }

    if (configStore.siteConfig.isHybrid) {
      return (
        <NavigationHybridCart
          navigationCart={<NavigationCart isText={true} />}
          navigationProposal={<NavigationProposal isText={true} />}
        />
      );
    }

    const shouldUseSimple =
      uiStore.isMobile && configStore.cart.button.simplifiedMobile;
    const navigationCart = shouldUseSimple ? (
      <MobileNavigationCart />
    ) : (
      <NavigationCart />
    );

    const navigationProposal = shouldUseSimple ? (
      <MobileNavigationProposal />
    ) : (
      <NavigationProposal />
    );

    return (
      <div className="NavigationCart__wrapper">
        {!configStore.siteConfig.proposalEnabled && navigationCart}
        {configStore.siteConfig.proposalEnabled && navigationProposal}
        {this.cartIsPopover() && <NavigationCartPopover />}
      </div>
    );
  };

  cartIsPopover = () => {
    const { accountStore, configStore } = this.props;

    if (!accountStore.account) {
      return null;
    }

    const isRetailer = accountStore.account.is_retailer;
    const modalType = configStore.cart.type;

    return isRetailer || modalType === ProductAddedToCardModalType.POPOVER;
  };

  renderNavigationTop = () => {
    const { configStore } = this.props;

    const moveShoppingCartLoginRight =
      !configStore.topNavigation.moveShoppingCartLoginRight;

    return (
      <Navbar className="CommonNavigation__top d-none d-lg-block">
        <Container className="CommonNavigation__top-container">
          <div className="CommonNavigation__top-inner">
            <NavigationMenuRow items={this.getMenuBarLinks()} />
            <div
              className={classNames(
                !moveShoppingCartLoginRight &&
                  'CommonNavigation__top-right-navigation-shopping-buttons-wrapper'
              )}
            >
              <Nav className="CommonNavigation__top-right-navigation">
                {configStore.topNavigation.moveSearchbarTopNavigation && (
                  <TopNavigationSearchButton
                    onShowSearchOnTopNavigation={this.toggleSearch}
                  />
                )}
                <NavigationNewsletterDropdown />
                <NavigationRegionalSettingsMenu />
                {configStore.wishlist.enabled && (
                  <NavItem>
                    <NavigationWishListButton />
                  </NavItem>
                )}
                {configStore.siteConfig.isWebStore && (
                  <NavItem>
                    <AccountLinks />
                  </NavItem>
                )}
              </Nav>
              {!moveShoppingCartLoginRight && (
                <div className="CommonNavigation__top-right-cart-wrapper">
                  {this.getNavigationCart()}
                  {this.cartIsPopover() && <NavigationCartPopover />}
                </div>
              )}
            </div>
          </div>
          {configStore.topNavigation.moveSearchbarTopNavigation &&
            this.state.showSearchOnDesktop && (
              <Row className="justify-content-center pb-2">
                {configStore.vehiclePartSearch.enabled && (
                  <div>
                    <LicensePlateInput
                      handleOnSubmit={this.handleVehicleSearch}
                      clearAfterSubmit
                    />
                  </div>
                )}
                <Col sm="5">
                  <QuickSearch
                    handleFocus={this.onInputFocus}
                    handleBlur={this.onInputBlur}
                    toggleSearch={() => null}
                  />
                </Col>
              </Row>
            )}
        </Container>
      </Navbar>
    );
  };

  renderNavigationTopBalance = () => {
    return (
      <Navbar className="CommonNavigation__top d-none d-lg-block">
        <Container className="CommonNavigation__top-container">
          <div className="CommonNavigation__top-inner">
            <NavigationMenuRow items={this.getMenuBarLinks()} />
            <AccountLinks />
          </div>
        </Container>
      </Navbar>
    );
  };

  renderNavigationMiddle = () => {
    const { isSticky, showSearchOnMobile, inputHasFocus } = this.state;

    const ifShouldShowFullHeader = this.shouldShowFullHeader();

    return (
      <Navbar className="CommonNavigation__middle" expand="lg">
        <Container className="CommonNavigation__middle-container">
          <CommonNavigationMiddle
            isSticky={isSticky}
            onShowSearchOnMobile={this.toggleSearch}
            onSearchKeyPress={this.onKeyPress}
            inputFocus={this.onInputFocus}
            inputBlur={this.onInputBlur}
            inputHasFocus={inputHasFocus}
            shouldShowSearchOnMobile={showSearchOnMobile}
            ifShouldShowFullHeader={ifShouldShowFullHeader}
          />
        </Container>
      </Navbar>
    );
  };

  renderNavigationBottom = () => {
    const { configStore } = this.props;

    if (
      this.shouldHideMixedContent() ||
      configStore.navigation.sidebar.enabled
    ) {
      return null;
    }

    return (
      <>
        <NavigationSingleLevelMenuRow
          items={this.getBottomBarLinks()}
          className="CommonNavigation__bottom"
        />
        {configStore.navigation.megaMenu.enabled && (
          <NavigationTopLevelMegaMenu debouncer={this.debouncedHover} />
        )}
      </>
    );
  };

  getAutosuggestInput = () => {
    return document.querySelector('.CommonNavigation .QuickSearch input');
  };

  renderNavigationPopover = () => {
    return <NavigationPopover navigationIsSticky={this.shouldHideSticky()} />;
  };

  render() {
    const { configStore, translationStore, uiStore } = this.props;
    const { isSticky } = this.state;
    const { mobileNavigation } = uiStore;

    const useStickyHeaderOnStartup =
      uiStore.isDesktop && configStore.topNavigation.useStickyHeaderOnStartup;
    const ifShouldShowFullHeader = this.shouldShowFullHeader();

    const storeLogo = translationStore.storeLogo;
    return (
      <div className="CommonNavigation__full-header-background">
        <NavigationLinkCarousel />
        <div className="CommonNavigation__header-background">
          {uiStore.isDesktop && (
            <div
              className={classNames('CommonNavigation', {
                'CommonNavigation--show-full-header':
                  !useStickyHeaderOnStartup && !isSticky,
                'CommonNavigation--hide-sticky': this.shouldHideSticky(),
                'CommonNavigation--scroll-logo': useStickyHeaderOnStartup,
                'CommonNavigation--mobile-navigation-open':
                  this.shouldShowMobileMenu(),
                'CommonNavigation--forced-static': !!window.isSSR,
              })}
            >
              {!configStore.siteConfig.isHomePage && this.renderNavigationTop()}
              {configStore.siteConfig.isHomePage &&
                configStore.account.isMoneyTransfer &&
                this.renderNavigationTopBalance()}
            </div>
          )}
          <Sticky
            className={classNames('CommonNavigation', {
              'CommonNavigation--show-full-header':
                !useStickyHeaderOnStartup && ifShouldShowFullHeader,
              'CommonNavigation--hide-sticky': this.shouldHideSticky(),
              'CommonNavigation--scroll-logo': useStickyHeaderOnStartup,
              'CommonNavigation--mobile-navigation-open':
                this.shouldShowMobileMenu(),
              'CommonNavigation--forced-static': !!window.isSSR,
            })}
            name="top"
            onFixedToggle={this.onFixedToggle}
            ref={(ref) => (this.stickyElement = ref)}
            positionRecheckInterval={10}
            holderProps={{
              className: 'CommonNavigation__sticky-placeholder',
            }}
          >
            {this.renderNavigationMiddle()}
            {configStore.siteConfig.isShoppingCenter && uiStore.isDesktop && (
              <div className="CommonNavigation__shopping-center--separator" />
            )}
            {!uiStore.isDesktop &&
              configStore.topNavigation.headerMobileSectionSlider && (
                <NavigationSectionSlider
                  items={this.getBottomBarLinks()}
                  className="CommonNavigation__bottom"
                />
              )}
            {this.shouldShowMobileMenu() && (
              <ClickOutside
                onClickOutside={mobileNavigation.toggle}
                excludedSelector=".MobileNavigationButton, .modal"
              >
                <MobileNavigation
                  shouldHideProductContent={this.shouldHideMixedContent()}
                  navigation={mobileNavigation}
                />
              </ClickOutside>
            )}
            {uiStore.isDesktop && this.renderNavigationBottom()}
            {uiStore.navigationPopover.contentId &&
              this.renderNavigationPopover()}
          </Sticky>
        </div>
        {!uiStore.isDesktop &&
          configStore.mobileSettings.showStoreLogo &&
          storeLogo.length > 0 && (
            <Container className="CommonNavigation__mobile-logo-container">
              <div className="CommonNavigation__mobile-logo">
                <NavigationLogo />
              </div>
            </Container>
          )}
        <ReactResizeDetector
          handleHeight
          onResize={this.onResize}
          refreshMode="throttle"
          refreshRate={100}
          refreshOptions={{ leading: true, trailing: true }}
        />
      </div>
    );
  }
}

CommonNavigation.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  categoryStore: modelOf(CategoryStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  menuStore: modelOf(MenuStore).isRequired,
  sectionStore: modelOf(SectionStore).isRequired,
  translationStore: modelOf(TranslationStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  customNavigationLinkStore: modelOf(CustomNavigationLinkStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  location: RouterPropTypes.location.isRequired,
};

export default inject(
  'accountStore',
  'categoryStore',
  'configStore',
  'menuStore',
  'sectionStore',
  'translationStore',
  'uiStore',
  'customNavigationLinkStore',
  'routeService'
)(withRouter(CommonNavigation));
