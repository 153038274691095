import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { blockTestRender } from '../../../util/process';

export const PageSkeleton = () => {
  return (
    <div className="PageSkeleton">
      {blockTestRender() && <Skeleton height={40} />}
      <div className="Skeleton__spacer" />
      {blockTestRender() && <Skeleton height={20} />}
      <div className="Skeleton__spacer" />
      {blockTestRender() && <Skeleton count={1.6} />}
      <div className="Skeleton__spacer" />
      {blockTestRender() && <Skeleton height={window.innerWidth * 1.3} />}
    </div>
  );
};

export default PageSkeleton;
